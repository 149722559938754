import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6cf5322"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }
const _hoisted_2 = { class: "form-wrapper" }
const _hoisted_3 = { class: "img-wrapper" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Contact = _resolveComponent("Contact", true)!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data)
      ? (_openBlock(), _createBlock(_component_Container, {
          key: 0,
          size: "medium"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Flex, {
              justifyContent: "space-between",
              alignItems: "center",
              wrap: "wrap"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_Contact, {
                    Title: _ctx.data.Title,
                    Text: _ctx.data.Text,
                    Radio: _ctx.data.Radio,
                    Checkbox: _ctx.data.Checkbox,
                    Input: _ctx.data.Input,
                    Button: _ctx.data.Button
                  }, null, 8, ["Title", "Text", "Radio", "Checkbox", "Input", "Button"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _ctx.backendUrl + _ctx.data.Img.data.attributes.url,
                    alt: ""
                  }, null, 8, _hoisted_4)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}