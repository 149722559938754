
import { defineComponent } from "vue";

//Template
import Footer from "./components/template/Footer.vue";
import Header from "./components/template/Header.vue";

export default defineComponent({
  components: { Header, Footer },
  setup() {
    return {};
  },
});
