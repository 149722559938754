import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    value: _ctx.selectedLang,
    options: _ctx.selectLangOptions,
    onInput: _ctx.updateSelectedLang
  }, null, 8, ["value", "options", "onInput"]))
}