
export default {
  props: {
    percent: { type: Number, default: 0 },
    reset: { type: Boolean, default: false },
    icon: { type: String },
  },
  // eslint-disable-next-line
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;

    return { backendUrl };
  },
};
