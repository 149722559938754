import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-622c0312"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
          key: 0,
          innerHTML: _ctx.value,
          class: _normalizeClass(`title ${_ctx.size} ${_ctx.color}`)
        }, null, 8, ["innerHTML", "class"]))
      : _createCommentVNode("", true),
    (!_ctx.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
          key: 1,
          class: _normalizeClass(`title ${_ctx.size} ${_ctx.color}`)
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}