
import { defineComponent, onMounted, ref } from "vue";

import fetchData from "@/utils/axios";

//Layouts
import Container from "../layouts/Container.vue";
import Flex from "../layouts/Flex.vue";

//Atoms
import Title from "../atoms/Title.vue";
import Text from "../atoms/Text.vue";
import Button from "../atoms/Button.vue";
import LocalSelector from "@/components/molecules/LocalSelector.vue";

export default defineComponent({
  components: { LocalSelector, Container, Title, Flex, Text, Button },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref(null);
    const error = ref(null);

    const isExpanded = ref(false);

    onMounted(async () => {
      try {
        const res = await fetchData("/api/global", {
          populate: [
            "Navigation.Logo",
            "Navigation.Logo.Image",
            "Navigation.Link",
          ],
        });

        data.value = res.data.data.attributes;
      } catch (err) {
        error.value = err;
      }
    });

    return { backendUrl, data, error, isExpanded };
  },
});
