
import { defineComponent } from "vue";
import Button from "../../atoms/Button.vue";
import Text from "../../atoms/Text.vue";
import Title from "../../atoms/Title.vue";
import Container from "../../layouts/Container.vue";

export default defineComponent({
  components: { Title, Container, Text, Button },
  props: {
    block: {
      type: Object,
    },
  },
  setup() {
    return {};
  },
});
