
import { defineComponent, PropType } from "vue";

//Layouts

//Atoms
import Title from "../../atoms/Title.vue";

export default defineComponent({
  components: { Title },
  props: {
    title: {
      type: String,
    },
    background: {
      type: String,
    },
    scrollTo: {
      type: Object as PropType<{ id: string; img: string }>,
    },
    full: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
});
