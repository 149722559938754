
import { onMounted, onUnmounted, PropType, ref } from "vue";
// import Swiper core and required modules
import { Pagination, Autoplay, Navigation } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

// Import Swiper styles
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/pagination/pagination.scss"; // Pagination module

//Layouts
import Flex from "../layouts/Flex.vue";

//Atoms
import Title from "../atoms/Title.vue";
import Text from "../atoms/Text.vue";
import Button from "../atoms/Button.vue";
import ProgressBar from "../atoms/ProgressBar.vue";

//Types
import { Slide } from "@/types/slide";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Flex,
    Title,
    Text,
    Button,
    ProgressBar,
  },
  props: {
    slides: { type: Array as PropType<Slide[]> },
  },
  // eslint-disable-next-line
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const width = ref(0);
    const reset = ref(false);

    const onSlideChange = () => {
      reset.value = true;
      setTimeout(() => (reset.value = false), 1);
    };

    const handleResize = () => {
      width.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      backendUrl,
      width,
      reset,
      onSlideChange,
      modules: [Navigation, Pagination, Autoplay],
    };
  },
};
