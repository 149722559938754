
import { defineComponent, onMounted, ref } from "vue";

import fetchData from "@/utils/axios";

//Layouts
import Container from "@/components/layouts/Container.vue";

//Molecules
import Contact from "@/components/molecules/form/Contact.vue";
import Flex from "@/components/layouts/Flex.vue";

export default defineComponent({
  components: { Container, Contact, Flex },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref(null);
    const error = ref(null);

    onMounted(async () => {
      try {
        const res = await fetchData("/api/contact-form", {
          populate: { ContactForm: { populate: "*" } },
        });

        data.value = res.data.data.attributes.ContactForm;
      } catch (err) {
        error.value = err;
      }
    });

    return { backendUrl, data, error };
  },
});
