import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-604cd5a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.label + '-checkbox',
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createElementVNode("label", {
      for: _ctx.label + '-checkbox'
    }, [
      _createVNode(_component_Text, {
        value: _ctx.label,
        size: "small"
      }, null, 8, ["value"])
    ], 8, _hoisted_3)
  ]))
}