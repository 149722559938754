import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06344bae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      value: _ctx.label,
      full: true,
      reverse: _ctx.radioValue === _ctx.value ? true : false,
      color: _ctx.radioValue === _ctx.value ? 'violet' : 'light'
    }, null, 8, ["value", "reverse", "color"]),
    _withDirectives(_createElementVNode("input", {
      type: "radio",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      value: _ctx.radioValue
    }, null, 8, _hoisted_2), [
      [_vModelRadio, _ctx.value]
    ])
  ]))
}