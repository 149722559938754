import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-617bedd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "paragraph" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          innerHTML: _ctx.value,
          class: _normalizeClass(`paragraph ${_ctx.size} ${_ctx.color} ${_ctx.weight}`)
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    (!_ctx.value)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(`paragraph ${_ctx.size} ${_ctx.color} ${_ctx.weight}`)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}