import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66bae0e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Text = _resolveComponent("Text")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Container, { size: "medium" }, {
      default: _withCtx(() => [
        _createVNode(_component_Title, {
          value: _ctx.block?.Title,
          tag: "h2"
        }, null, 8, ["value"]),
        _createVNode(_component_Text, {
          value: _ctx.block?.Text,
          size: "large"
        }, null, 8, ["value"]),
        (_ctx.block)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.block.Button.Href
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  value: _ctx.block?.Button.Text,
                  gradient: true,
                  color: "light"
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}