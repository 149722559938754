
//Layouts
import Container from "../../layouts/Container.vue";

//Organismes
import Swiper from "@/components/organismes/Swiper.vue";

export default {
  components: {
    Swiper,
    Container,
  },
  props: {
    block: {
      type: Object,
    },
  },
  // eslint-disable-next-line
  setup() {
    return {};
  },
};
