import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6144ebc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "articles-list" }
const _hoisted_2 = { class: "card-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Article = _resolveComponent("Article")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { size: "large" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Title, {
          value: _ctx.block?.Title
        }, null, 8, ["value"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (article, index) => {
            return (_openBlock(), _createBlock(_component_Article, {
              key: index,
              id: article?.id,
              slug: article?.attributes.Slug,
              title: article?.attributes.Title,
              date: new Date(article?.attributes.Date),
              url: article?.attributes.Url,
              icon: article?.attributes.Logo.data.attributes.url
            }, null, 8, ["id", "slug", "title", "date", "url", "icon"]))
          }), 128))
        ]),
        _withDirectives(_createVNode(_component_Button, {
          value: _ctx.block?.Button.Text,
          gradient: true,
          onClick: _ctx.loadMore
        }, null, 8, ["value", "onClick"]), [
          [_vShow, _ctx.hasNext]
        ])
      ])
    ]),
    _: 1
  }))
}