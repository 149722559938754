import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e6c14f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Text = _resolveComponent("Text")!
  const _component_Radio = _resolveComponent("Radio")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_Title, { value: _ctx.Title }, null, 8, ["value"]),
    _createVNode(_component_Text, {
      value: _ctx.Text,
      size: "large"
    }, null, 8, ["value"]),
    _createVNode(_component_Flex, {
      class: "button-container",
      justifyContent: "space-between",
      alignItems: "center",
      wrap: "wrap"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Radio, {
          modelValue: _ctx.form.radio,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.radio) = $event)),
          radioValue: _ctx.Radio[0].Value,
          label: _ctx.Radio[0].Label
        }, null, 8, ["modelValue", "radioValue", "label"]),
        _createVNode(_component_Radio, {
          modelValue: _ctx.form.radio,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.radio) = $event)),
          radioValue: _ctx.Radio[1].Value,
          label: _ctx.Radio[1].Label
        }, null, 8, ["modelValue", "radioValue", "label"])
      ]),
      _: 1
    }),
    _createVNode(_component_Flex, {
      class: "ckeckbox-container",
      justifyContent: "space-between",
      alignItems: "center",
      wrap: "wrap"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.form.checkbox[0],
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.checkbox[0]) = $event)),
          label: _ctx.Checkbox[0].Label
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.form.checkbox[1],
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.checkbox[1]) = $event)),
          label: _ctx.Checkbox[1].Label
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.form.checkbox[2],
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.checkbox[2]) = $event)),
          label: _ctx.Checkbox[2].Label
        }, null, 8, ["modelValue", "label"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Input, {
        label: _ctx.Input[0].Label,
        modelValue: _ctx.form.name,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.name) = $event)),
        placeholder: _ctx.Input[0].Placeholder
      }, null, 8, ["label", "modelValue", "placeholder"]),
      (_ctx.form.radio === 'mail')
        ? (_openBlock(), _createBlock(_component_Input, {
            key: 0,
            label: _ctx.Input[1].Label,
            modelValue: _ctx.form.mail,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.mail) = $event)),
            placeholder: _ctx.Input[1].Placeholder
          }, null, 8, ["label", "modelValue", "placeholder"]))
        : _createCommentVNode("", true),
      (_ctx.form.radio === 'phone')
        ? (_openBlock(), _createBlock(_component_Input, {
            key: 1,
            type: "tel",
            label: _ctx.Input[2].Label,
            modelValue: _ctx.form.phone,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.phone) = $event)),
            placeholder: _ctx.Input[2].Placeholder
          }, null, 8, ["label", "modelValue", "placeholder"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Flex, {
      class: "submit",
      justifyContent: "flex-end"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Button, {
          onClick: _ctx.submit,
          value: _ctx.Button?.Text,
          color: "light",
          full: true
        }, null, 8, ["onClick", "value"])
      ]),
      _: 1
    })
  ], 32))
}