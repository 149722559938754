
import { defineComponent, ref } from "vue";
import Dropdown from "@/components/atoms/Dropdown.vue";

interface Option {
  value: string;
  label: string;
  image: string;
}

export default defineComponent({
  components: { Dropdown },
  setup: function () {
    const selectLangOptions: Option[] = [
      { value: "fr", label: "Français", image: "france" },
      { value: "en", label: "English", image: "usa" },
      { value: "de", label: "Deutsch", image: "germany" },
    ];
    const selectedLang = ref<string>(selectLangOptions[0].value);

    // Retrieve the selected language from local storage (if available)
    const storedLang = localStorage.getItem("selectedLang");
    const availableLanguages = selectLangOptions.map((option) => option.value);

    if (storedLang && availableLanguages.includes(storedLang)) {
      selectedLang.value = storedLang;
    } else {
      // If the stored language is not available, set the default language to french
      selectedLang.value = "fr";
    }

    const updateSelectedLang = (option: string) => {
      selectedLang.value = option;
      localStorage.setItem("selectedLang", option);
      window.location.reload();
    };

    return {
      selectedLang,
      selectLangOptions,
      updateSelectedLang,
    };
  },
});
