
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    direction: {
      type: String as PropType<
        "row" | "row-reverse" | "column" | "column-reverse"
      >,
      validator: (v: string) =>
        ["row", "row-reverse", "column", "column-reverse"].indexOf(v) !== -1,
    },
    justifyContent: {
      type: String as PropType<
        | "flex-start"
        | "flex-end"
        | "center"
        | "space-between"
        | "space-around"
        | "space-evenly"
      >,
      default: "space-between",
      validator: (v: string) =>
        [
          "flex-start",
          "flex-end",
          "center",
          "space-between",
          "space-around",
          "space-evenly",
        ].indexOf(v) !== -1,
    },
    alignItems: {
      type: String as PropType<
        "flex-start" | "flex-end" | "center" | "baseline" | "stretch"
      >,
      validator: (v: string) =>
        ["flex-start", "flex-end", "center", "baseline", "stretch"].indexOf(
          v
        ) !== -1,
    },
    wrap: {
      type: String as PropType<"wrap" | "nowrap" | "wrap-reverse">,
      validator: (v: string) =>
        ["wrap", "nowrap", "wrap-reverse"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
