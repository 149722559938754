import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bf2474c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "dropdown-content"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "header-link",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _createElementVNode("img", {
        style: {"width":"20px"},
        src: _ctx.getImg(_ctx.selectedOption.image),
        alt: ""
      }, null, 8, _hoisted_2),
      _createVNode(_component_Text, {
        value: _ctx.selectedOption.label,
        size: "header-link",
        color: "dark"
      }, null, 8, ["value"])
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isDropdownOpen)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: option.value,
                  onClick: ($event: any) => (_ctx.selectOption(option.value))
                }, [
                  _createElementVNode("img", {
                    style: {"width":"20px"},
                    src: _ctx.getImg(option.image),
                    alt: ""
                  }, null, 8, _hoisted_5),
                  _createTextVNode(" " + _toDisplayString(option.label), 1)
                ], 8, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}