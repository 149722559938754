
import { defineComponent, onMounted, ref } from "vue";

import fetchData from "@/utils/axios";

//Layouts
import Container from "../layouts/Container.vue";
import Flex from "../layouts/Flex.vue";

//Atoms
import Text from "../atoms/Text.vue";

export default defineComponent({
  components: { Container, Flex, Text },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref(null);
    const error = ref(null);

    onMounted(async () => {
      try {
        const res = await fetchData("/api/global", {
          populate: ["Footer.Logo", "Footer.Logo.Image"],
        });

        data.value = res.data.data.attributes;
      } catch (err) {
        error.value = err;
      }
    });

    return { backendUrl, data, error };
  },
});
