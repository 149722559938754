
import { defineComponent, PropType } from "vue";
import Text from "./Text.vue";

export default defineComponent({
  components: { Text },
  props: {
    value: {
      type: String,
    },
    full: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<"s" | "m" | "l">,
      validator: (v: string) => ["s", "m", "l"].indexOf(v) !== -1,
    },
    color: {
      type: String as PropType<"light" | "dark-light" | "violet">,
      validator: (v: string) => ["light", "dark", "violet"].indexOf(v) !== -1,
    },
    weight: {
      type: String as PropType<"bold">,
      validator: (v: string) => ["bold"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
