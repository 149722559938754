
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String as PropType<"light" | "dark">,
      validator: (v: string) => ["light", "dark"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
