import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { size: "medium" }, {
    default: _withCtx(() => [
      _createVNode(_component_Text, {
        value: _ctx.block?.text,
        size: "large"
      }, null, 8, ["value"])
    ]),
    _: 1
  }))
}