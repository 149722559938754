import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f3e5a8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`jumbotron ${_ctx.full && 'full'}`)
  }, [
    _createElementVNode("div", {
      class: "background",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.background + ')' })
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Title, {
          value: _ctx.title,
          tag: "h1",
          color: "dark"
        }, null, 8, ["value"])
      ]),
      (_ctx.scrollTo)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "#next",
            class: "goDowm"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.scrollTo.img,
                alt: ""
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}