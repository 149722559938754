import axios from "axios";
import qs from "qs";

const fetchData = async (
  url: string,
  params: Record<string, unknown>
  /* eslint-disable @typescript-eslint/no-explicit-any */
): Promise<any> => {
  const query = qs.stringify(params, {
    encodeValuesOnly: true,
  });

  const storedLang = localStorage.getItem("selectedLang") ?? "fr";

  return axios.get(
    process.env.VUE_APP_BACKEND_URL +
      url +
      "?" +
      query +
      "&locale=" +
      storedLang
  );
};

export default fetchData;
