import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c75a6574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Text = _resolveComponent("Text")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper", true)!

  return (_openBlock(), _createBlock(_component_Swiper, {
    modules: $setup.modules,
    "slides-per-view": 1,
    spaceBetween: $setup.width,
    pagination: { clickable: true, dynamicBullets: true },
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
    },
    onSlideChange: $setup.onSlideChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.slides, (slide, index) => {
        return (_openBlock(), _createBlock(_component_SwiperSlide, { key: index }, {
          default: _withCtx(() => [
            _createVNode(_component_Flex, {
              class: "slide-container",
              justifyContent: "space-between"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Flex, {
                  class: "left",
                  direction: "column",
                  justifyContent: "space-between"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (slide.Title)
                        ? (_openBlock(), _createBlock(_component_Title, {
                            key: 0,
                            value: slide.Title,
                            tag: "h3",
                            size: "small",
                            color: "dark"
                          }, null, 8, ["value"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Text, {
                        value: slide.Text
                      }, null, 8, ["value"])
                    ]),
                    _createVNode(_component_Flex, {
                      justifyContent: "space-between",
                      alignItems: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: slide.Button.Href
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Button, {
                              value: slide.Button.Text,
                              color: "violet",
                              full: true
                            }, null, 8, ["value"])
                          ]),
                          _: 2
                        }, 1032, ["to"]),
                        _createVNode(_component_ProgressBar, {
                          class: "swiper-button-next",
                          reset: $setup.reset,
                          percent: 100
                        }, null, 8, ["reset"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("img", {
                    src: $setup.backendUrl + slide.Img.data.attributes.url,
                    alt: ""
                  }, null, 8, _hoisted_2)
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["modules", "spaceBetween", "navigation", "onSlideChange"]))
}