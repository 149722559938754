
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String as PropType<"small" | "medium" | "large">,
      validator: (v: string) => ["small", "medium", "large"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
