import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

//Styles
import "@/styles/app.scss";

import { VueReCaptcha } from "vue-recaptcha-v3";
import { VueShowdownPlugin } from "vue-showdown";

createApp(App)
  .use(router)
  .use(VueShowdownPlugin)
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
    },
  })
  .mount("#app");
