import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3831dc94"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "id", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`input ${_ctx.color}`)
  }, [
    _createElementVNode("label", {
      for: _ctx.label.toLowerCase() + '-input'
    }, [
      _createVNode(_component_Text, {
        value: _ctx.label,
        size: "xsmall"
      }, null, 8, ["value"])
    ], 8, _hoisted_1),
    _withDirectives(_createElementVNode("input", {
      type: _ctx.type,
      id: _ctx.label.toLowerCase() + '-input',
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      placeholder: _ctx.placeholder,
      min: "1"
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, _ctx.value]
    ])
  ], 2))
}