
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    value: {
      type: String,
    },
    size: {
      type: String as PropType<
        "header-link" | "xsmall" | "small" | "medium" | "large" | "xlarge"
      >,
      validator: (v: string) =>
        ["header-link", "xsmall", "small", "medium", "large", "xlarge"].indexOf(
          v
        ) !== -1,
    },
    color: {
      type: String as PropType<
        "light" | "dark" | "violet" | "orange" | "yellow" | "blue"
      >,
      validator: (v: string) =>
        ["light", "dark", "violet", "orange", "yellow", "blue"].indexOf(v) !==
        -1,
    },
    weight: {
      type: String as PropType<"bold">,
      validator: (v: string) => ["bold"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
