
import { computed, defineComponent, ref, watchEffect } from "vue";
import Text from "@/components/atoms/Text.vue";

interface Option {
  value: string;
  label: string;
  image: string;
}

export default defineComponent({
  components: { Text },
  props: {
    value: {
      type: String,
    },
    options: {
      type: Array as () => Option[],
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDropdownOpen = ref(false);
    const selectedValue = ref(props.value);
    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const selectOption = (option: string) => {
      selectedValue.value = option;
      // Emit the selected option to the parent component
      emit("input", option);
      toggleDropdown();
    };

    const selectedOption = computed(() => {
      // Find the label for the selected value
      return props.options.find(
        (option) => option.value === selectedValue.value
      );
    });

    // Close the dropdown when clicking outside
    const closeDropdownOnClickOutside = (event: MouseEvent) => {
      if (isDropdownOpen.value) {
        const dropdown = document.querySelector(".dropdown");

        if (dropdown && !dropdown.contains(event.target as Node)) {
          isDropdownOpen.value = false;
        }
      }
    };

    // Listen for clicks on the document to close the dropdown
    watchEffect(() => {
      if (isDropdownOpen.value) {
        document.addEventListener("click", closeDropdownOnClickOutside);
      } else {
        document.removeEventListener("click", closeDropdownOnClickOutside);
      }
    });

    const getImg = (image: string) => {
      const url = require.context("../../assets/flags/", false);
      return url("./" + image + ".png");
    };

    return {
      isDropdownOpen,
      selectedOption,
      toggleDropdown,
      selectOption,
      getImg,
    };
  },
});
