
import { defineComponent, ref } from "vue";

import { IReCaptchaComposition, useReCaptcha } from "vue-recaptcha-v3";

import Swal from "sweetalert2";

//Atoms
import Title from "@/components/atoms/Title.vue";
import Text from "@/components/atoms/Text.vue";
import Flex from "@/components/layouts/Flex.vue";
import Button from "@/components/atoms/Button.vue";
import Checkbox from "@/components/atoms/form/Checkbox.vue";
import Input from "@/components/atoms/form/Input.vue";
import Radio from "@/components/atoms/form/Radio.vue";
import axios from "axios";

export default defineComponent({
  components: {
    Title,
    Text,
    Flex,
    Button,
    Checkbox,
    Input,
    Radio,
  },
  props: {
    Button: Object,
    Checkbox: Array,
    Input: Array,
    Radio: Array,
    Text: String,
    Title: String,
  },
  setup(props) {
    const form = ref({
      radio: "phone",
      checkbox: [false, false, false],
      name: null,
      mail: null,
      phone: null,
    });

    const { executeRecaptcha, recaptchaLoaded } =
      useReCaptcha() as IReCaptchaComposition;

    const submit = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha("login");

      // Do stuff with the received token.
      try {
        await axios.post(process.env.VUE_APP_BACKEND_URL + "/api/messages", {
          data: {
            Name: form.value.name || null,
            Email: form.value.mail || null,
            Phone: form.value.phone || null,
            When: JSON.stringify(
              form.value.checkbox
                .map((result, index) => {
                  // eslint-disable-next-line
                  if (result) return (props?.Checkbox?.[index] as any).Label;
                })
                .filter((value) => value !== undefined)
            ),
          },
          token,
        });

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Message Envoyé",
          toast: true,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      } catch (err) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Erreur: Message Non Envoyé",
          toast: true,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }

      form.value.radio = "phone";
      form.value.checkbox = [false, false, false];
      form.value.name = null;
      form.value.mail = null;
      form.value.phone = null;
    };

    return { form, submit };
  },
});
