
import { defineComponent } from "vue";

//Layouts
import Flex from "@/components/layouts/Flex.vue";
import Separator from "@/components/layouts/Separator.vue";

//Atoms
import Title from "@/components/atoms/Title.vue";
import Text from "@/components/atoms/Text.vue";

export default defineComponent({
  components: { Text, Separator, Flex, Title },
  props: {
    id: {
      type: Number,
    },
    slug: {
      type: String,
    },
    title: {
      type: String,
    },
    date: {
      type: Date,
    },
    url: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  setup(props) {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const datetime = props.date?.toLocaleDateString("fr-Fr", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return { backendUrl, datetime };
  },
  computed: {
    computedUrl(): string {
      return this.url ? this.url : `/post/${this.id}/${this.slug}`;
    },
  },
});
