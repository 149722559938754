
import { computed, defineComponent } from "vue";
import Text from "../Text.vue";

export default defineComponent({
  components: { Text },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    return { value };
  },
});
