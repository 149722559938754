import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-percentage"]
const _hoisted_2 = { class: "progress-left" }
const _hoisted_3 = { class: "progress-right" }
const _hoisted_4 = { class: "progress-value" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "progress",
    "data-percentage": $props.percent
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(`${!$props.reset && 'progress-bar'}`)
      }, null, 2)
    ]),
    _createElementVNode("span", _hoisted_3, [
      _createElementVNode("span", {
        class: _normalizeClass(`${!$props.reset && 'progress-bar'}`)
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        src: $setup.backendUrl + '/uploads/skip_1ed0a7aa46.svg',
        alt: ""
      }, null, 8, _hoisted_5)
    ])
  ], 8, _hoisted_1))
}