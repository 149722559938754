
import { defineComponent, onMounted, ref } from "vue";

import fetchData from "@/utils/axios";

//Layouts
import Container from "@/components/layouts/Container.vue";

//Atoms
import Title from "@/components/atoms/Title.vue";
import Button from "@/components/atoms/Button.vue";

//Molecules
import Article from "@/components/molecules/cards/Article.vue";

export default defineComponent({
  components: { Container, Article, Title, Button },
  props: {
    block: {
      type: Object,
    },
  },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref<Array<Record<string, unknown>> | null>(null);
    const error = ref(null);
    const page = ref(1);
    const hasNext = ref(true);

    const fetchNews = async () => {
      try {
        const res = await fetchData("/api/posts", {
          populate: "*",
          pagination: {
            page: page.value,
            pageSize: 4,
          },
        });

        const resNext = await fetchData("/api/posts", {
          populate: "*",
          pagination: {
            page: page.value + 1,
            pageSize: 4,
          },
        });

        if (!(resNext.data.data.length > 0)) {
          hasNext.value = false;
        }

        page.value++;

        if (data.value) data.value.push(...res.data.data);
        else data.value = res.data.data;
      } catch (err) {
        error.value = err;
      }
    };

    const loadMore = async () => {
      return fetchNews();
    };

    onMounted(async () => {
      return fetchNews();
    });

    return { backendUrl, data, error, hasNext, loadMore };
  },
});
