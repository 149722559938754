import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`button ${_ctx.full && 'full'} ${_ctx.small && 'small'} ${
      _ctx.reverse && 'reverse'
    } ${_ctx.gradient && 'gradient'} ${_ctx.color}`)
  }, [
    _createVNode(_component_Text, {
      value: _ctx.value,
      size: _ctx.size,
      color: _ctx.color,
      weight: _ctx.weight
    }, null, 8, ["value", "size", "color", "weight"])
  ], 2))
}