
import { defineComponent, PropType } from "vue";

type Title = "h1" | "h2" | "h3";

export default defineComponent({
  props: {
    value: {
      type: String,
    },
    tag: {
      type: String as PropType<Title>,
      default: "h1",
      validator: (v: string) => ["h1", "h2", "h3"].indexOf(v) !== -1,
    },
    size: {
      type: String as PropType<"small" | "medium" | "large" | "xlarge">,
      validator: (v: string) =>
        ["small", "medium", "large", "xlarge"].indexOf(v) !== -1,
    },
    color: {
      type: String as PropType<"light" | "dark" | "red" | "violet" | "green">,
      validator: (v: string) =>
        ["light", "dark", "red", "violet", "green"].indexOf(v) !== -1,
    },
  },
  setup() {
    return {};
  },
});
