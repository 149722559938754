import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { size: "medium" }, {
    default: _withCtx(() => [
      ($props.block)
        ? (_openBlock(), _createBlock(_component_Swiper, {
            key: 0,
            slides: $props.block?.Slides
          }, null, 8, ["slides"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}