
import { computed, defineComponent, PropType } from "vue";
import Text from "../Text.vue";

export default defineComponent({
  components: { Text },
  props: {
    modelValue: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    color: {
      type: String as PropType<"light" | "dark">,
      validator: (v: string) => ["light", "dark"].indexOf(v) !== -1,
    },
    type: {
      type: String as PropType<"text" | "number" | "tel">,
      default: "text",
      validator: (v: string) => ["text", "number", "tel"].indexOf(v) !== -1,
    },
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => {
        if (props.type === "number" && value && value < 1) {
          value = 1;
        }
        emit("update:modelValue", value);
      },
    });

    return { value };
  },
});
