import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/functionality",
    name: "Functionality",
    component: () => import("../views/Functionality.vue"),
  },
  {
    path: "/prices",
    name: "Prices",
    component: () => import("../views/Prices.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("../views/Jobs.vue"),
  },
  {
    path: "/post/:id/:slug",
    name: "Post",
    component: () => import("../views/Post.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return document
        .querySelector(to.hash)
        ?.scrollIntoView({ behavior: "smooth" });
    }
    return document.getElementById("app")?.scrollIntoView();
  },
});

export default router;
