
import { defineComponent, onMounted, ref } from "vue";

import fetchData from "@/utils/axios";

//Block
import Jumbortron from "@/components/template/blocks/Jumbortron.vue";
import CTA from "@/components/template/blocks/CTA.vue";
import Paragraph from "@/components/template/blocks/Paragraph.vue";
import Carousel from "@/components/template/blocks/Carousel.vue";
import Contact from "@/components/template/blocks/form/Contact.vue";
import Articles from "@/components/template/blocks/Articles.vue";

export default defineComponent({
  name: "Home",
  components: {
    Carousel,
    Jumbortron,
    CTA,
    Paragraph,
    Contact,
    Articles,
  },
  setup() {
    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    const data = ref(null);
    const error = ref(null);

    onMounted(async () => {
      try {
        const res = await fetchData("/api/pages", {
          filters: {
            Title: {
              $eq: "Home",
            },
          },
          populate: {
            Hero: {
              populate: "*",
            },
            Blocks: {
              populate: {
                Logos: { populate: "*" },
                Input: { populate: "*" },
                Checkbox: { populate: "*" },
                Radio: { populate: "*" },
                Button: { populate: "*" },
                Slides: { populate: "*" },
                NewsCards: { populate: "*" },
              },
            },
          },
        });

        data.value = res.data.data[0].attributes;
      } catch (err) {
        error.value = err;
      }
    });

    return { backendUrl, data, error };
  },
});
